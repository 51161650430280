import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SupportedLocale } from '../enums';
import { isSupportedLocale } from '../utils';

@Injectable({
  providedIn: 'root',
})
export class LocaleService {
  private readonly defaultLocale = SupportedLocale.Hu;
  private readonly localeKey = '@alteo/sinergy/locale';

  private currentLocale: SupportedLocale;

  public get locale(): SupportedLocale {
    return this.currentLocale;
  }

  public get supportedLocales(): SupportedLocale[] {
    return Object.values(SupportedLocale);
  }

  constructor(private readonly translateService: TranslateService) {
    const stored = localStorage.getItem(this.localeKey);
    if (isSupportedLocale(stored)) {
      this.currentLocale = stored;
    }

    /**
     * Guess locale based on browser language.
     */
    if (!this.currentLocale) {
      const browserLang = navigator.language;
      this.currentLocale = Object.values(SupportedLocale).find(locale => browserLang.includes(locale));
    }

    /**
     * Use the fallback value if no other solution worked.
     */
    if (!this.currentLocale) {
      this.currentLocale = this.defaultLocale;
    }

    localStorage.setItem(this.localeKey, this.currentLocale);
    this.updateLocale(this.currentLocale);
  }

  public updateLocale(locale: SupportedLocale): void {
    this.currentLocale = locale;

    localStorage.setItem(this.localeKey, locale);
    this.translateService.setDefaultLang(locale);
    this.translateService.use(locale);
  }
}
